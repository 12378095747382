import React, { createElement } from 'react';
import sanitizeHtml from 'sanitize-html';

interface IDangerousHtml {
    __html: string;
}

export interface SanitizeHTMLProps {
    html: string;
    className?: string;
    options?: sanitizeHtml.IOptions;
    tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div' | 'li' | 'ul';
}

const defaultOptions: sanitizeHtml.IOptions = {
    allowedTags: ['br', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div', 'li', 'ul'],
    allowedAttributes: {
        a: ['class', 'href', 'target'],
        br: ['class'],
    },
};

const concateOptions = (options: sanitizeHtml.IOptions) => {
    if (!options) return defaultOptions;
    try {
        return Object.keys(options).reduce((acc, key) => {
            // allowedTags, nonBooleanAttributes, selfClosing, allowedSchemes, allowedSchemesAppliedToAttributes
            if (Array.isArray(acc[key]) && Array.isArray(options[key])) {
                return {
                    ...acc,
                    [key]: acc[key].concat(options[key]),
                };
            }
            // allowedAttributes, allowedSchemesByTag
            if (acc[key] instanceof Object && options[key] instanceof Object) {
                return {
                    ...acc,
                    [key]: Object.assign(acc[key], options[key]),
                };
            }
            // disallowedTagsMode, allowProtocolRelative, enforceHtmlBoundary, parseStyleAttributes
            if (typeof options[key] === 'string' || typeof options[key] === 'boolean') {
                return {
                    ...acc,
                    [key]: options[key],
                };
            }
            // На случай если у нас нет этого options в default
            if (typeof acc[key] === 'undefined') {
                return {
                    ...acc,
                    [key]: options[key],
                };
            }
            return acc;
        }, defaultOptions);
    } catch (error) {
        console.error(error);
        return defaultOptions;
    }
};

/**
 * @param {string} dirty
 * @param {sanitizeHtml.IOptions} options
 * @returns {IDangerousHtml}
 */
const sanitize = (dirty: string, options: sanitizeHtml.IOptions | undefined): IDangerousHtml => {
    const concatedOptions = concateOptions(options);

    return {
        __html: sanitizeHtml(dirty, concatedOptions),
    };
};

const BaseSanitizer: React.FC<SanitizeHTMLProps> = ({ html, tag, className, options }) => {
    return createElement(tag, {
        className,
        dangerouslySetInnerHTML: sanitize(html, options),
    });
};

export default BaseSanitizer;
