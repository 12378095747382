import React from 'react';
import Head from 'next/head';
import clsx from 'clsx';

// Components
import BlockHeader from '@pages/crypto/BlockHeader';
import BlockNetwork from '@pages/crypto/BlockNetwork';
import BlockForWhom from '@pages/crypto/BlockForWhom';
import BlockAdvantages from '@pages/crypto/BlockAdvantages';
import BlockHowStartWork from '@pages/crypto/HowStartWork';
import BlockStats from '@pages/crypto/BlockStats';
import BlockCrypto from '@components/pages/crypto/BlockCrypto';
import BlockPayments from '@components/pages/crypto/BlockPayments';

import useWidget from '@utils/hooks/useWidget';

// Helpers
import useI18n from '@i18n';
import { generateRoute } from '@utils/router';
import getReferralParams from '@utils/getReferralParams';

import auth from '@config/auth.json';

import styles from '@pages/crypto/Crypto.module.scss';

const WIDGET_ID = 'registration-widget';

interface ICrypto {
    widget?: {
        needPhone: boolean;
        hideSocials: boolean;
    };
}

const Crypto: React.FC<ICrypto> = ({ widget }) => {
    const { t, locale } = useI18n();
    const refParams = getReferralParams();

    const { isLoading, showWidget } = useWidget(WIDGET_ID, {
        needPhone: !!widget?.needPhone,
        hideSocials: !!widget?.hideSocials,
    });

    const registrationLink: string = generateRoute(
        `${process.env.NEXT_PUBLIC_INSTANCE_PREFIX}${auth.REG_URL}`,
        {
            locale,
        },
    );

    const handleRegistration = () => {
        if (isLoading) return;
        if (widget || refParams) {
            showWidget();
        } else {
            window.open(registrationLink);
        }
    };

    return (
        <>
            <Head>
                <title>{t('Crypto offers in ePN')}</title>
                <meta
                    name="description"
                    content={t('Monetize your traffic with crypto offers in ePN description')}
                />
            </Head>
            <main className="pb-[140px]">
                <div className={clsx(styles.darkWrapper, 'pt-5 xs:pt-10')}>
                    <BlockHeader handleRegistration={handleRegistration} />
                    <BlockStats />
                    <BlockCrypto />
                    <BlockNetwork />
                </div>
                <BlockForWhom />
                <BlockPayments />
                <div className={clsx(styles.darkWrapper, styles.darkWrapper__twin)}>
                    <BlockAdvantages />
                    <BlockHowStartWork handleRegistration={handleRegistration} />
                </div>
            </main>
        </>
    );
};

export default Crypto;
