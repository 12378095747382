import useI18n from '@i18n';
import BaseSanitizer from '@components/base/BaseSanitizer';
import ContainerWithLayout from '@components/pages/cryptoadvertiser/ContainerWithLayout';

import styles from './BlockCrypto.module.scss';

import Logos from './img/logos.png';

const BlockCrypto: React.FC = () => {
    const { t } = useI18n();

    return (
        <section className={styles.block}>
            <ContainerWithLayout colorName="greyGradient">
                <div className={styles.landingContainer}>
                    <div className={styles.wrapper}>
                        <div className={styles.logos}>
                            <img src={Logos} alt="" />
                        </div>
                        <BaseSanitizer
                            html={t('Together with Ton, we launched a crypto vertical within ePN')}
                            tag="h2"
                            className={styles.title}
                        />
                        <BaseSanitizer
                            html={t('and thus became the first CPA network..')}
                            tag="p"
                            className={styles.text}
                        />
                    </div>
                </div>
            </ContainerWithLayout>
        </section>
    );
};

export default BlockCrypto;
